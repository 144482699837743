<template>
  <v-tooltip
    v-if="airtable && airtable.enabled"
    top
  >
    <template #activator="{ on }">
      <div
        :class="{'v-list-item v-list-item--link theme--light': fromBoard}"
        @click="$emit('click')"
        v-on="isDisabled ? on : undefined"
      >
        <div :class="{'certificate-disabled' : isDisabled }">
          Create Certificate
        </div>
      </div>
    </template>
    <span> {{ toolTipLabel }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'CreateCertificateOption',
  props: {
    project: {
      type: Object,
      default: () => {
      }
    },
    fromBoard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
    airtable() {
      return this.company.airtableData;
    },
    deletedUser() {
      const userExists = this.project.parseObject.get('parent');
      const userEnabled = !this.project.parseObject.get('parent')?.get('disabled');
      return !(userExists && userEnabled);
    },
    toolTipLabel() {
      return this.deletedUser ? 'User no longer exists' : 'Missing url, contact Customer Success';
    },
    phaseName() {
      return this.project.parseObject.get('phase').get('title').toLowerCase();
    },
    missingUrl() {
      return !this.airtable[this.phaseName];
    },
    isDisabled() {
      return this.deletedUser || this.missingUrl;
    }
  },
  methods: {
    click() {
      if (!this.isDisabled) {
        this.$emit('click');
      }
    }
  }
};
</script>

<style scoped>
  .certificate-disabled {
    color: grey;
    cursor: initial;
  }
</style>
