<template>
  <v-dialog
    v-if="isEnabled"
    v-model="showDialog"
    max-width="500"
  >
    <v-card class="pa-3">
      <v-card-title class="headline">
        Issue Certificate
      </v-card-title>
      <v-row justify="center">
        <v-img
          :src="require(`@/assets/img/certificates/${phase}.png`)"
          max-width="120"
          class="mb-2"
        />
      </v-row>
      <v-card-text>
        Give recognition to <span class="font-weight-bold">{{ projectCreator }}</span>
        for completing the <span class="font-weight-bold">{{ phaseFullTitle }}</span>
        phase and create a certificate.
      </v-card-text>
      <v-card-text v-if="reminder">
        Reminder: This user received a <span class="font-weight-bold">{{ reminder.phase }}</span>
        certificate on <span class="font-weight-bold">
          {{ formatDate(reminder.certificationDate) }}</span>
        for the project  <span class="font-weight-bold">{{ project.title }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <app-button
          secondary
          @click="closeCertificateDialog"
        >
          Close
        </app-button>
        <app-button
          primary
          class="ml-5"
          @click="createCertificate"
        >
          Create
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { REDBOX_IDEA } from '@kickbox/common-util';
import moment from 'moment';
import airtableService from '@/services/airtableService';

export default {
  name: 'CertificateDialog',
  data() {
    return {
      showDialog: false,
      project: null,
      url: '',
      reminder: null
    };
  },
  computed: {
    ...mapGetters(['projectCertificate', 'company']),
    phaseFullTitle() {
      return this.projectCertificate?.phaseFullTitle;
    },
    phase() {
      return this.phaseFullTitle?.split(' ')[0];
    },
    projectCreator() {
      if (this.project) {
        return this.project.creator?.name || this.project.creator || 'Deleted user';
      }
      return null;
    },
    isEnabled() {
      return this.projectCertificate && this.project && this.company.airtableData.enabled;
    }
  },
  async created() {
    this.$watch('projectCertificate', this.initialize, { immediate: true });
  },
  methods: {
    createCertificate() {
      this.closeCertificateDialog();
      window.open(this.url);
    },
    closeCertificateDialog() {
      this.showDialog = false;
      this.reminder = null;
      this.$store.commit('setProjectCertificate', null);
    },
    async initialize() {
      if (this.projectCertificate && this.company.airtableData.enabled) {
        this.project = this.$store.getters.projectById(this.projectCertificate.projectId);
        if (!this.project.creator.active) {
          return;
        }
        const { id: projectId, creator: { name, email } } = this.project;
        const urlForm = this.company.airtableData[this.phase.toLowerCase()];
        this.url = `${urlForm}?prefill_Kickboxer+Name=${name}&prefill_Kickboxer+Email=${email}&prefill_Project+ID=${projectId}&hide_Project+ID=true`;
        if (this.phase === REDBOX_IDEA.title) {
          this.url = this.url.concat(`&prefill_Phase=${this.phaseFullTitle}&hide_Phase=true`);
        }
        this.reminder = await airtableService
          .certificateExists({
            projectId,
            table: this.phase,
            email: this.project.creator.email,
            phase: this.phaseFullTitle
          });
        this.showDialog = !!urlForm;
      }
    },
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    }
  }
};
</script>
